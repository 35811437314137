<template>
  <div class="d-flex flex-column bg-light rounded mb-5 px-5">
    <div class="row">
      <div class="col py-5 rounded-top d-flex">
        <router-link
          :key="'modal-info-title-link' + reisetermin.id"
          class="font-size-h4 font-weight-bolder mr-2"
          :to="{ name: 'reisetermine-view', params: { reiseterminId: reisetermin.id } }"
          target="_blank"
        >
          <i class="fas fa-info text-dark-75" />
          {{ reisetermin.reiseterminkuerzel }}
        </router-link>
        <ReiseterminDateShiftButton
          v-allowedRoles="[Role.ADMIN, Role.DOM]"
          :reiseterminWorkflowFristDate="reisetermin.reiseterminWorkflowFrist.fristDatum"
          :reisekuerzel="reisetermin.reise.reisekuerzel"
          :startdatum="reisetermin.startdatum"
        ></ReiseterminDateShiftButton>
      </div>
    </div>
    <div class="row">
      <div class="d-flex align-items-center flex-wrap flex-fill px-3">
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-clock-2 icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-sm">{{ $t('COLUMNNAMES.Frist') }}</span>
            <span class="font-weight-bolder font-size-h5"></span>
            <!-- TODO: Workaround für Fehler im Core. reiseterminWorkflowFrist sollte nicht null sein -->
            <WorkdayAmpelWidget
              :displayNegativeDays="true"
              :days="reisetermin.reiseterminWorkflowFrist ? reisetermin.reiseterminWorkflowFrist.frist : 0"
              :description="
                reisetermin.reiseterminWorkflowFrist
                  ? $options.filters.dateWorkday(reisetermin.reiseterminWorkflowFrist.fristDatum)
                  : 'Fehlerhafte Frist'
              "
            ></WorkdayAmpelWidget>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-users icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column text-dark-75">
            <span class="font-weight-bolder font-size-sm">{{ $t('COMMON.PAX') }}</span>
            <PaxWidget
              :paxObject="reisetermin.pax"
              :kalkPax="reisetermin.metadata.kalkPax"
              :erwartetePax="reisetermin.metadata.erwartetePax"
              :durchfuehrbareMinPax="reisetermin.metadata.durchfuehrbareMinPax"
            ></PaxWidget>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-user-settings icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-sm">{{ $t('REISEN.COMMON.EDITOR') }}</span>
            <span class="font-weight-bolder font-size-h5">
              <span class="text-dark-50 font-weight-bold"></span>
              <span v-if="!reisetermin.bearbeiter">-</span>
              <div class="font-weight-bolder font-size-sm" v-if="reisetermin.bearbeiter">
                {{ $store.getters.getUsernameById(reisetermin.bearbeiter) }}
              </div>
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-sm">{{ $t('COMMON.KOMMENTARE') }}</span>
            <span class="font-weight-bolder font-size-h5"></span>
            {{ reisetermin.comments ? reisetermin.comments.filter(comment => !comment.parentId).length : 0 }}
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-globe icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-sm">
              {{ $t('COLUMNNAMES.ReiseterminBlankData_Garantiert') }}
            </span>
            <span class="font-weight-bolder font-size-h5"></span>
            <i :class="reisetermin.garantiert ? 'fas fa-check text-success' : 'fas fa-times text-danger'" />
          </div>
        </div>
        <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
          <span class="mr-4"><i class="flaticon-globe icon-2x text-muted font-weight-bold"></i></span>
          <div class="d-flex flex-column">
            <TransferszenarioToolTip target="transferszenario-info" />
            <span id="transferszenario-info" class="text-dark-75 font-weight-bolder font-size-sm">
              {{ $t('COLUMNNAMES.Transferszenario') }}
            </span>
            <span class="font-weight-bolder font-size-h5"></span>
            {{ (metadata && metadata.transferszenario) || '-' }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="reisetermin.reise" class="row mb-2">
      <div class="d-flex align-items-center flex-wrap flex-fill px-3">
        <ReiseterminViewBesonderheitenBase
          :besonderheiten="reisetermin.besonderheiten"
          :reisekuerzel="reisetermin.reise.reisekuerzel"
          :startdatum="reisetermin.startdatum"
        ></ReiseterminViewBesonderheitenBase>
      </div>
    </div>
  </div>
</template>

<script>
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import WorkdayAmpelWidget from '@/components/common/workday-ampel.vue';
import ReiseterminViewBesonderheitenBase from '@/components/produkte/reisetermine/besonderheiten-base.vue';
import ReiseterminDateShiftButton from '@/components/produkte/reisetermine/reisetermin-date-shift-button.vue';
import TransferszenarioToolTip from '@/components/produkte/reisetermine/transferszenario-tooltip.vue';
export default {
  name: 'WorkflowManagerInfoPanelReiseterminComponent',
  components: {
    PaxWidget,
    WorkdayAmpelWidget,
    ReiseterminViewBesonderheitenBase,
    ReiseterminDateShiftButton,
    TransferszenarioToolTip,
  },
  props: {
    reisetermin: { type: Object, required: false },
  },
  computed: {
    metadata() {
      return this.reisetermin.metadata;
    },
  },
};
</script>
